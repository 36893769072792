<!-- <section class="section services" id='services'>
    <div class="container">
        <div class="section-box">
            <div data-aos="fade-up" data-aos-duration="1000">
                <h3 class="section-title">
                    <span class='n-section-title'>03.</span> {{"Services.Title" | translate}}
                </h3>
            </div>

            <div *ngFor='let project of "Services.Projects" | translate; let i = index' class="d-flex proyect-container" data-aos="fade-up" data-aos-duration="1000">
                <owl-carousel-o class='owl-carousel-o' [options]="customOptions" *ngIf="i%2==0">
                    <ng-container *ngFor="let img of project.imgs">
                        <ng-template carouselSlide [id]="project.Title+slide+i">
                            <div #imgContainer class='img-container'>
                                <img class='img-feature-proyect rounded' [src]="img" [alt]="project.Title">
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
                <div class="img-feature-proyect-container" data-aos="fade-up" data-aos-duration="1000" *ngIf="i%2==0">
                    <div class="img-feature-proyect-box">
                        <div class="img-container" style='width: auto;'>
                            <img class='img-feature-proyect rounded' [src]="project.imgs[0]" [alt]="project.imgs[0]" width='600px'>
                        </div>
                    </div>
                </div>
                <div [class.proyect-info-right]='i%2==0' [class.proyect-info-left]='i%2!=0' class="h-100" style="background-image: url('{{project.img}}');">
                    <div class="proyect-box">
                        <h4 [class.text-right]='i%2==0' [class.text-left]='i%2!=0' class="feature-proyect">{{"Services.Label" | translate}}</h4>
                        <h5 [class.text-right]='i%2==0' [class.text-left]='i%2!=0' class="proyect-name">{{project.Title}}</h5>

                        <div class="proyect-description-box" (click)='debug()'>
                            <p class="mb-0" [innerHTML]="project.Description">
                            </p>
                        </div>
                        <ul [class.justify-content-end]='i%2==0' [class.justify-content-start]='i%2!=0' class="proyect-skills">
                            <li *ngFor='let technology of project.Technologies' class="ml-3">
                              <span class="underline technology">{{technology}}</span>
                            </li>
                        </ul>
                        <div [class.text-right]='i%2==0' [class.text-left]='i%2!=0' class="proyect-links">
                            <a *ngIf="project['ghLink']" style='color: inherit' [href]="project['ghLink']" target="_blank"><i class="ml-3 fab fa-github"></i></a>
                            <a *ngIf="project['demoLink']" style='color: inherit' [href]="project['demoLink']" target="_blank"><i class="ml-3 fas fa-external-link-alt"></i></a>
                        </div>
                    </div>
                </div>
                <owl-carousel-o class='owl-carousel-o' [options]="customOptions" *ngIf="i%2!=0">
                    <ng-container *ngFor="let img of project.imgs">
                        <ng-template carouselSlide [id]="project.Title+slide+i">
                            <div class='img-container'>
                                <img class='img-feature-proyect rounded' [src]="img" [alt]="project.Title">
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
                <div class="img-feature-proyect-container" data-aos="fade-up" data-aos-duration="1000" *ngIf="i%2!=0">
                    <div class="img-feature-proyect-box">
                        <div class="img-container" style='width: auto;'>
                            <img class='img-feature-proyect rounded' [src]="project.imgs[0]" [alt]="project.imgs[0]" width='600px'>
                        </div>
                    </div>
                </div>
            </div>
            <div>
            </div>
        </div>
    </div>
</section> -->
<section class="section services" id="services">
    <div class="container">
        <div class="section-box">
            <div class="content">
                <h2>Our Services</h2>
                <div class="row">
                    <div class="col-md-4 mb-4">
                        <div class="service-item">
                            <img src="assets/images/app-development.jpg" alt="Application Development">
                            <h3>Application Development & Maintenance</h3>
                            <p>Transform your ideas into robust applications that drive business growth and efficiency.</p>
                        </div>
                    </div>
                    <div class="col-md-4 mb-4">
                        <div class="service-item">
                            <img src="assets/images/ai.jpg" alt="Artificial Intelligence">
                            <h3>Artificial Intelligence</h3>
                            <p>Harness the power of AI to automate processes, gain insights, and enhance decision-making capabilities.</p>
                        </div>
                    </div>
                    <div class="col-md-4 mb-4">
                        <div class="service-item">
                            <img src="assets/images/cloud-solutions.jpg" alt="Cloud Solutions">
                            <h3>Cloud Solutions</h3>
                            <p>Leverage scalable and secure cloud services to optimize operations and flexibility.</p>
                        </div>
                    </div>
                    <!-- Repeat for each service -->
                </div>
            </div>
        </div>
    </div>
</section>
