<nav [@animateMenu] class="navbar main-navbar on-top" [class.nav-shadow]='this.pageYPosition>0'>
    <div class="container">
        <a class="navbar-brand" [routerLink]="'inicio'">
            <img src='assets/images/logo.png' width='250' />
        </a>
        <ul ngbNav #nav="ngbNav" class="menu-ul">
            <li ngbNavItem (click)='analyticsService.sendAnalyticEvent("click_about", "menu", "click")'>
                <a ngbNavLink (click)='scroll("home")'><span class="underline nav-text"> {{"Header.home" |
                        translate}}</span></a>
                <ng-template ngbNavContent>First content</ng-template>
            </li>
            <li ngbNavItem (click)='analyticsService.sendAnalyticEvent("click_experience", "menu", "click")'>
                <a ngbNavLink (click)='scroll("jobs")'><span class="underline nav-text"> {{"Header.about-us" |
                        translate}}</span></a>
                <ng-template ngbNavContent>Second content</ng-template>
            </li>
            <li ngbNavItem class="nav-item dropdown"
                (click)="analyticsService.sendAnalyticEvent('click_jobs', 'menu', 'click')">
                <a ngbNavLink class="nav-link dropdown-toggle" (click)="scroll('projects')" id="navbarDropdown"
                    role="button" aria-haspopup="true" aria-expanded="false">
                    <span class="underline nav-text">{{'Header.services' | translate}}</span>
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a class="dropdown-item" href="#submenu1" (click)="scroll('submenu1')">Application Development &
                        Maintenance
                    </a>
                    <a class="dropdown-item" href="#submenu2" (click)="scroll('submenu2')">Artificial Intelligence</a>
                    <a class="dropdown-item" href="#submenu3" (click)="scroll('submenu3')">Cloud Solutions</a>
                    <a class="dropdown-item" href="#submenu4" (click)="scroll('submenu4')">Cybersecurity</a>
                    <a class="dropdown-item" href="#submenu5" (click)="scroll('submenu5')">Data Engineering</a>
                    <a class="dropdown-item" href="#submenu6" (click)="scroll('submenu6')">Enterprise Applications and
                        Integrations</a>
                    <a class="dropdown-item" href="#submenu7" (click)="scroll('submenu7')">Quality Assurance and
                        Engineering</a>
                    <a class="dropdown-item" href="#submenu8" (click)="scroll('submenu8')">Software Product
                        Engineering</a>
                    <a class="dropdown-item" href="#submenu9" (click)="scroll('submenu9')">Enterprise Turnkey
                        Solutions</a>
                </div>
                <ng-template ngbNavContent>Second content</ng-template>
            </li>
            <li ngbNavItem class="nav-item dropdown"
                (click)="analyticsService.sendAnalyticEvent('click_contact', 'menu', 'click')">
                <a ngbNavLink class="nav-link dropdown-toggle" (click)="scroll('contact')" id="navbarDropdown"
                    role="button" aria-haspopup="true" aria-expanded="false">
                    <span class="underline nav-text">{{'Header.industries' | translate}}</span>
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a class="dropdown-item" href="#submenu1" (click)="scroll('submenu1')">Education</a>
                    <a class="dropdown-item" href="#submenu2" (click)="scroll('submenu2')">Financial Services</a>
                    <a class="dropdown-item" href="#submenu3" (click)="scroll('submenu3')">Healthcare</a>
                    <a class="dropdown-item" href="#submenu4" (click)="scroll('submenu4')">Manufacturing</a>
                    <a class="dropdown-item" href="#submenu5" (click)="scroll('submenu5')">Retail</a>
                    <a class="dropdown-item" href="#submenu6" (click)="scroll('submenu6')">Transportation &
                        Logistics</a>
                    <a class="dropdown-item" href="#submenu7" (click)="scroll('submenu7')">Real Estate</a>
                </div>
                <ng-template ngbNavContent>Second content</ng-template>
            </li>
            <li ngbNavItem class="nav-item dropdown"
                (click)="analyticsService.sendAnalyticEvent('click_experience', 'menu', 'click')">
                <a ngbNavLink class="nav-link dropdown-toggle" (click)="scroll('jobs')" id="navbarDropdown"
                    role="button" aria-haspopup="true" aria-expanded="false">
                    <span class="underline nav-text">{{'Header.partners' | translate}}</span>
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a class="dropdown-item" href="#submenu1" (click)="scroll('submenu1')">Skyvino Audit and Tax
                        Advisors
                    </a>
                </div>
                <ng-template ngbNavContent>Second content</ng-template>
            </li>
            <li ngbNavItem (click)='analyticsService.sendAnalyticEvent("click_jobs", "menu", "click")'>
                <a ngbNavLink (click)='scroll("proyects")'><span class="underline nav-text"> {{"Header.careers" |
                        translate}}</span></a>
                <ng-template ngbNavContent>Second content</ng-template>
            </li>
            <li ngbNavItem (click)='analyticsService.sendAnalyticEvent("click_contact", "menu", "click")'>
                <a ngbNavLink (click)='scroll("contact")'><span class="underline nav-text"> {{"Header.contact-us" |
                        translate}}</span></a>
                <ng-template ngbNavContent>Second content</ng-template>
            </li>

            <!-- <li>
                <div ngbDropdown class="d-inline-block">
                    <div class="language-container" id="dropdownForm1" ngbDropdownToggle>
                        <img *ngIf='languageFormControl.value == "en"' width="20px" src="https://www.worldometers.info/img/flags/us-flag.gif" alt="English">
                        <span *ngIf='languageFormControl.value == "en"' class="flag-text">English</span>

                        <img *ngIf='languageFormControl.value == "es"' width="20px" src="https://www.worldometers.info/img/flags/sp-flag.gif" alt="Español">
                        <span *ngIf='languageFormControl.value == "es"' class="flag-text">Español</span>
                        <svg class='arrow-language' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/></svg>
                    </div>
                    <div ngbDropdownMenu aria-labelledby="dropdownForm1">
                        <div (click)='changeLanguage("es")' style='border-bottom-left-radius: 0px !important;border-bottom-right-radius: 0px !important;' class="language-container language-container-selector active" [class.active]='languageFormControl.value == "es"'>
                            <img width="20px" src="https://www.worldometers.info/img/flags/sp-flag.gif" alt="Español">
                            <span class="flag-text">Español</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2 arrow-active" viewBox="0 0 16 16">
                                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                              </svg>
                        </div>
                        <div (click)='changeLanguage("en")' style="margin-top: 1px !important; border-top-left-radius: 0px !important; border-top-right-radius: 0px !important;" class="language-container language-container-selector" [class.active]='languageFormControl.value == "en"'>
                            <img width="20px" src="https://www.worldometers.info/img/flags/us-flag.gif" alt="English">
                            <span class="flag-text">English</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2 arrow-active" viewBox="0 0 16 16">
                                <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </li> -->
        </ul>
        <div class="menu-wrapper">
            <div [class.animate]='responsiveMenuVisible' (click)='responsiveMenuVisible = !responsiveMenuVisible'
                class="hamburger-menu"></div>
        </div>
        <div class="menu-responsive" [ngStyle]="{'pointer-events': !responsiveMenuVisible ? 'none' : '' }">
            <aside [class.aside-show]='responsiveMenuVisible' [class.nav-shadow]='this.pageYPosition>0' class="on-top">
                <nav>
                    <ol>
                        <li (click)='analyticsService.sendAnalyticEvent("click_about", "menu", "click")'>
                            <a (click)='scroll("about")'>
                                {{"Header.home" | translate}}
                            </a>
                        </li>
                        <li (click)='analyticsService.sendAnalyticEvent("click_experience", "menu", "click")'>
                            <a (click)='scroll("jobs")'>
                                {{"Header.about-us" | translate}}
                            </a>
                        </li>
                        <li (click)='analyticsService.sendAnalyticEvent("click_jobs", "menu", "click")'>
                            <a (click)='scroll("proyects")'>
                                {{"Header.services" | translate}}
                            </a>
                        </li>
                        <li (click)='analyticsService.sendAnalyticEvent("click_about", "menu", "click")'>
                            <a (click)='scroll("about")'>
                                {{"Header.industries" | translate}}
                            </a>
                        </li>
                        <li (click)='analyticsService.sendAnalyticEvent("click_experience", "menu", "click")'>
                            <a (click)='scroll("jobs")'>
                                {{"Header.partners" | translate}}
                            </a>
                        </li>
                        <li (click)='analyticsService.sendAnalyticEvent("click_jobs", "menu", "click")'>
                            <a (click)='scroll("proyects")'>
                                {{"Header.careers" | translate}}
                            </a>
                        </li>
                        <li (click)='analyticsService.sendAnalyticEvent("click_jobs", "menu", "click")'>
                            <a (click)='scroll("proyects")'>
                                {{"Header.contact-us" | translate}}
                            </a>
                        </li>
                        <!-- <li>
                            <div class="language-container">
                                <div (click)='changeLanguage("es")' style='border-bottom-left-radius: 0px !important;border-bottom-right-radius: 0px !important;' class="language-container language-container-selector active" [class.active]='languageFormControl.value == "es"'>
                                    <img src="https://www.worldometers.info/img/flags/sp-flag.gif" alt="Español">
                                    <span class="flag-text">Español</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2 arrow-active" viewBox="0 0 16 16">
                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                                      </svg>
                                </div>
                                <div (click)='changeLanguage("en")' style="margin-top: 1px !important; border-top-left-radius: 0px !important; border-top-right-radius: 0px !important;" class="language-container language-container-selector" [class.active]='languageFormControl.value == "en"'>
                                    <img src="https://www.worldometers.info/img/flags/us-flag.gif" alt="English">
                                    <span class="flag-text">English</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2 arrow-active" viewBox="0 0 16 16">
                                        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
                                    </svg>
                                </div>
                            </div>
                      </li> -->
                        <!-- <li>
                            <img src="https://www.worldometers.info/img/flags/us-flag.gif" alt="English">
                        </li> -->


                    </ol>
                </nav>
            </aside>
        </div>
    </div>
</nav>