<main>
    <app-banner></app-banner>
    <app-services></app-services>
    <!-- <app-about></app-about> -->
    <!-- <app-jobs></app-jobs> -->
    <!-- <app-proyects></app-proyects> -->
    <!-- <app-more-proyects></app-more-proyects> -->
    <!-- <app-contact></app-contact> -->
     
</main>

